import React from "react";
import Link from "next/link";
import { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import StarCheckbox from "../button/StarCheckbox";
import { CiStreamOn } from "react-icons/ci";
import { getDefYear } from "../../constants/main";
const ScoreCard = ({
  isToday,
  data,
  store,
  isStandardPlan,
  isPremiumPlan,
  index,
  user,
  leagueFromUrl,
  myScoreCenter,
  updateMyScoreCenter = "",
  showOnlyHalftime = false,
  isJapanLeague = false,
  isBeforeFiveDays = false,
}) => {
  const isLive = data.status === "live";
  const [embedData, setEmbedData] = useState({});
  const widthRef = useRef(null);
  const leagueYear = getDefYear(leagueFromUrl);

  const homeResult = Number(data.score?.split(" ")[1].split("-")[0]);
  const awayResult = Number(data.score?.split(" ")[1].split("-")[1]);
  const SqHomeResult = Number(data.sq_score?.split(" ")[1].split("-")[0]);
  const SqAwayResult = Number(data.sq_score?.split(" ")[1].split("-")[1]);
  const matchupLinkTime = embedData?.game_info?.game_time?.split(" ").join("T") + "Z";
  const uniqueKey = isToday
    ? data.team + data.opponent + "/" + dayjs(data.game_time_nyc).format("YYYY-MM-DD")
    : data.team + data.opponent + "/" + dayjs(data.date).format("YYYY-MM-DD");

  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsActive(!document.hidden);
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (data === undefined) return;
    if (data?.game_id === undefined) return;
    if (data?.game_id === null) return;
    if ((isToday && isActive) || isJapanLeague) {
      console.log("response from betting api");
      fetch(`https://embed.shotquality.com/data/betting?gid=${data.game_id}`)
        .then((res) => res.json())
        .then((data) => {
          setEmbedData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (isLive && isActive) {
      const interval = setInterval(() => {
        fetch(`https://embed.shotquality.com/data/betting?gid=${data.game_id}`)
          .then((res) => res.json())
          .then((data) => {
            setEmbedData(data);
            if (formatGameClock(data, leagueFromUrl) === "Final") clearInterval(interval);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [isActive]);

  let checkBoxStatus = myScoreCenter?.includes(uniqueKey);

  if (showOnlyHalftime) {
    if (leagueFromUrl === "ncaa" || leagueFromUrl === "college_mens") {
      if (!embedData?.game_info?.game_clock.includes("Q1 0:00")) return null;
    } else if (leagueFromUrl === "nba") {
      if (!embedData?.game_info?.game_clock.includes("Q2 0:00")) return null;
    } else {
      return null;
    }
  }

  return (
    <div
      className="gameCard d-flex flex-column justify-content-between"
      style={!isPremiumPlan && !isStandardPlan && index > 0 ? { filter: "blur(8px)" } : null}
      ref={widthRef}
      key={`${data.team}_${data.opponent}_${data.conference}`}
    >
      {updateMyScoreCenter !== "" && (
        <div className="form-check check-box pt-0">
          <StarCheckbox
            checked={checkBoxStatus}
            handleCheck={() => {
              updateMyScoreCenter(uniqueKey);
            }}
            title={checkBoxStatus ? "Added to My ScoreCenter!" : "Add to My ScoreCenter"}
            style={{ fontSize: "1.5rem" }}
            id={uniqueKey}
          />
        </div>
      )}

      <div className="gameCardHeader">
        <GameCardHeaderScoreTeamName
          leagueType={leagueFromUrl}
          leagueYear={leagueYear}
          team={isToday ? data.away : data.opponent}
          score={isToday || isJapanLeague ? embedData?.game_info?.away_score : awayResult}
        />

        <GameMetaInfo
          isLive={isLive}
          date={data?.date}
          embedData={embedData}
          isToday={isToday}
          home={data.team}
          away={data.opponent}
          leagueType={leagueFromUrl}
          game_id={data.game_id}
        />
        <GameCardHeaderScoreTeamName
          leagueType={leagueFromUrl}
          leagueYear={leagueYear}
          team={isToday ? data.home : data.team}
          score={isToday || isJapanLeague ? embedData?.game_info?.home_score : homeResult}
        />
      </div>
      <GameCardContent
        isToday={isToday}
        embedData={embedData}
        homeResult={homeResult}
        awayResult={awayResult}
        isPremiumPlan={isPremiumPlan}
        isStandardPlan={isStandardPlan}
        SqHomeResult={SqHomeResult}
        SqAwayResult={SqAwayResult}
        data={data}
        isJapanLeague={isJapanLeague}
        isBeforeFiveDays={isBeforeFiveDays}
      />

      <div className="gameCardFooter">
        {isToday ? (
          <>
            <Link
              href={`/${leagueFromUrl}/matchup-output/${data.team}/${data.opponent}/${matchupLinkTime}`}
              className="scores-team-name-link live"
              target={"_blank"}
            >
              {"View Matchup"}
            </Link>
            {(isPremiumPlan || data.live_standard === 1) && (
              <>
                | <ScoreCenterLink data={data} />
              </>
            )}
          </>
        ) : (
          <>
            <ScoreCenterLink data={data} /> |
            <GameRecapLink data={data} leagueFromUrl={leagueFromUrl} leagueYear={leagueYear} />
          </>
        )}
      </div>

      <div className="GameCardPrediction text-center w-100 mt-3 mb-2" style={{ color: "black" }}>
        {
          // isLive && data?.current_total ? (
          //   <div>
          //     <strong>Live: </strong>
          //     {`O/U ${data?.current_total} ${data.team} ${
          //       data?.current_spread > 0 ? `+${data?.current_spread}` : `${data?.current_spread}`
          //     }`}
          //   </div>
          // ) : (
          data?.closing_total && (
            <div>
              <strong>CLOSE: </strong>
              {`O/U ${data.closing_total} ${data.team} ${
                data.closing_spread > 0 ? `+${data.closing_spread}` : `${data.closing_spread}`
              }`}
            </div>
          )
          // )
        }
        {data?.projected_away_score && (
          <div className="mt-1">
            <strong>PRE-GAME PREDICTION: </strong>
            {`O/U ${(data?.projected_away_score + data?.projected_home_score).toFixed(1)} ${data.team} ${(
              data?.projected_away_score - data?.projected_home_score
            ).toFixed(1)}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreCard;

export const GameCardHeaderScoreTeamName = ({ leagueType, leagueYear, team }) => {
  return (
    <Link
      href={leagueType === "japan_b1" ? "#" : `/team/${leagueType}/${leagueYear}/${team}`}
      className="scores-team-name"
    >
      <img src={`/static/media/logos/${team}.png`} alt={`${team}`} style={{ marginRight: "5px", height: "50px" }} />
      <h3> {team}</h3>
    </Link>
  );
};
export const GameMetaInfo = ({ isToday, date, embedData, isLive, home, away, leagueType, game_time_nyc, game_id }) => {
  let modifiedDate = date ? date.split(",")[0] : dayjs(game_time_nyc).format("ddd MMM D");

  let gameClock = formatGameClock(embedData, leagueType);
  return (
    <div className="gameDate">
      {isLive ? (
        <h3 className="text-center justify-center">
          <Link href={`/live?gid=${game_id}`} className="scores-team-name-link live" target={"_blank"}>
            {gameClock === "Final" ? (
              "Final"
            ) : (
              <>
                LIVE
                <CiStreamOn />
              </>
            )}
          </Link>
        </h3>
      ) : (
        <h3 className="text-center">
          {isToday ? (
            <Link href={`/live?gid=${game_id}`} className="scores-team-name-link live" target={"_blank"}>
              Final
            </Link>
          ) : (
            <Link href={`/${leagueType}/matchup-output/${home}/${away}/true`} target={"_blank"}>
              Final
            </Link>
          )}
        </h3>
      )}
      {isLive && gameClock !== "final" ? (
        <h4 className="text-center">{gameClock}</h4>
      ) : (
        <h4 className="text-center">{modifiedDate.trim()}</h4>
      )}
    </div>
  );
};

export const GameCardContent = ({
  isToday,
  embedData,
  homeResult,
  awayResult,
  isPremiumPlan,
  isStandardPlan,
  SqHomeResult,
  SqAwayResult,
  data,
  isJapanLeague = false,
  isBeforeFiveDays,
}) => {
  const isNotNullOrUndefined = (value) => value !== null && value !== undefined && !isNaN(value);

  const away_score = isToday || isJapanLeague ? embedData?.game_info?.away_score : +awayResult;
  const home_score = isToday || isJapanLeague ? embedData?.game_info?.home_score : +homeResult;
  const away_sq_score = isToday || isJapanLeague ? embedData?.game_info?.away_sq_score : +SqAwayResult;
  const home_sq_score = isToday || isJapanLeague ? embedData?.game_info?.home_sq_score : +SqHomeResult;
  const home_pps = isToday || isJapanLeague ? embedData?.pps?.home_pps : +data.pps?.toFixed(2);
  const away_pps = isToday || isJapanLeague ? embedData?.pps?.away_pps : +data.opp_pps?.toFixed(2);
  const home_sq_pps = isToday || isJapanLeague ? embedData?.pps?.home_sq_pps : +data.sq_pps?.toFixed(2);
  const away_sq_pps = isToday || isJapanLeague ? embedData?.pps?.away_sq_pps : +data.opp_sq_pps?.toFixed(2);

  const homeScoreDiff = Math.round((home_sq_score - home_score) * 10) / 10;
  const awayScoreDiff = Math.round((away_sq_score - away_score) * 10) / 10;
  const isValidScoreData = isNotNullOrUndefined(home_score) && isNotNullOrUndefined(away_score);
  const isValidScoreDataForPredicted = isNotNullOrUndefined(home_sq_score) && isNotNullOrUndefined(away_sq_score);
  const isValidPpsData = isNotNullOrUndefined(home_pps) && isNotNullOrUndefined(away_pps);
  const isValidPpsDataForPredicted = isNotNullOrUndefined(home_sq_pps) && isNotNullOrUndefined(away_sq_pps);
  const isHomeScoreDiffOnPoint = Math.abs(homeScoreDiff) < 3;
  const isAwayScoreDiffOnPoint = Math.abs(awayScoreDiff) < 3;
  const homeDiff = Math.round((home_score - home_sq_score) * 10) / 10;
  const awayDiff = Math.round((away_score - away_sq_score) * 10) / 10;
  const isEmoji = Math.abs(homeDiff - awayDiff) > 40;
  return (
    <div className="gameCardContent">
      <table>
        <tbody>
          {isValidScoreDataForPredicted && (
            <tr className="score">
              <td className="away">{away_score}</td>
              <td className="TitleDiv">
                <div>
                  <span>SCORE</span>
                </div>
              </td>
              <td className="home">{home_score}</td>
            </tr>
          )}
          {isValidScoreDataForPredicted && (
            <tr className="score predicted">
              {/* {isPremiumPlan || data.live_standard === 1 || !isBeforeFiveDays ? ( */}
              {isPremiumPlan || data.live_standard === 1 || (data.status !== "live" && isStandardPlan) ? (
                <td className="away">
                  {isEmoji ? (isAwayScoreDiffOnPoint ? "🎯" : awayScoreDiff > 0 ? "🥶" : "🔥") : away_sq_score}
                </td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}
              <td className="TitleDiv">
                <div className="TitleWrapper">
                  <span className="IconWrapper">
                    <i className="AppIcon" data-icon="icon.name.ball"></i>SHOT
                  </span>
                </div>
                <div>
                  <span className="quality">QUALITY</span>
                </div>
                <div>
                  <span>SCORE</span>
                </div>
              </td>

              {isPremiumPlan || data.live_standard === 1 || (data.status !== "live" && isStandardPlan) ? (
                <td className="home">
                  {isEmoji ? (isHomeScoreDiffOnPoint ? "🎯" : homeScoreDiff > 0 ? "🥶" : "🔥") : home_sq_score}
                </td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}
            </tr>
          )}
          {isValidPpsData && (
            <tr className="pps actual">
              {isPremiumPlan || isStandardPlan || (data.status !== "live" && isStandardPlan) ? (
                <td className="away">
                  <span className="points" style={{ marginLeft: "12px" }}>
                    {away_sq_pps > away_pps ? (
                      <span style={{ filter: "hue-rotate(130deg)" }}>{away_pps}🔺</span>
                    ) : (
                      <span>{away_pps}🔻</span>
                    )}
                  </span>
                  <span className="difference">{(away_sq_pps - away_pps).toFixed(2)}</span>
                </td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}

              <td className="info">
                <span style={{ fontSize: "12px" }}>PPS</span>
              </td>

              {isPremiumPlan || isStandardPlan || (data.status !== "live" && isStandardPlan) ? (
                <td className="home">
                  <span className="points" style={{ marginRight: "12px" }}>
                    {home_sq_pps > home_pps ? (
                      <span style={{ filter: "hue-rotate(130deg)" }}>🔺{home_pps.toFixed(2)}</span>
                    ) : (
                      <span>🔻{home_pps.toFixed(2)}</span>
                    )}
                  </span>
                  <span className="difference">
                    {home_sq_pps - home_pps > 0 ? "+" : null}
                    {(home_sq_pps - home_pps).toFixed(2)}
                  </span>
                </td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}
            </tr>
          )}
          {isValidPpsDataForPredicted && (
            <tr className="pps predicted">
              {isPremiumPlan || data.live_standard === 1 || (data.status !== "live" && isStandardPlan) ? (
                <td className="away">{away_sq_pps}</td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}
              <td className="TitleDiv">
                <div className="TitleWrapper">
                  <span className="IconWrapper">
                    <i className="AppIcon" data-icon="icon.name.ball"></i>SHOT
                  </span>
                </div>
                <div>
                  <span className="quality">QUALITY</span>
                </div>
                <div>
                  <span>PPS</span>
                </div>
              </td>

              {isPremiumPlan || data.live_standard === 1 || (data.status !== "live" && isStandardPlan) ? (
                <td className="home">{home_sq_pps}</td>
              ) : (
                <td className="away">
                  <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.6rem" }}>🔒</span>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
      <Outliers embedData={embedData} />
      {isStandardPlan && data.status === "live" && data.live_standard !== 1 && (
        <>
          <hr />
          <div className="text-center">
            <span style={{ textShadow: "0 0 10px #000, 0 0 5px #000", fontSize: "1.2rem" }}>🔒</span>{" "}
            <a href={"/welcome-premium"}>
              Upgrade your membership to Premium to unlock live ShotQuality Scores and ScoreCenter for all games.
            </a>
          </div>
        </>
      )}
    </div>
  );
};
export const Outliers = ({ embedData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const outliers = embedData?.outliers || {};
  const combinedDataArray = Object.values(outliers).flat();

  return combinedDataArray.length ? (
    <div className="OutlierWrapper text-center">
      {combinedDataArray && combinedDataArray.length > 1 && (
        <div className={`Outlier ${isFading ? "fade-out" : ""}`}>{combinedDataArray[currentIndex]}</div>
      )}
      {combinedDataArray && combinedDataArray.length === 1 && <div className="Outlier">{combinedDataArray[0]}</div>}
    </div>
  ) : null;
};

export const ScoreCenterLink = ({ data }) => {
  return (
    <Link href={`/live?gid=${data?.game_id}`} className="scores-team-name-link live" target={"_blank"}>
      ScoreCenter
    </Link>
  );
};
export const GameRecapLink = ({ data, leagueFromUrl, leagueYear }) => {
  return (
    <Link
      href={`/game/${leagueFromUrl}/${leagueYear}/${data.team}/${data.game_link}`}
      className="scores-team-name-link"
      target={"_blank"}
    >
      Game Recap
    </Link>
  );
};

export const formatGameClock = (embedData, leagueType) => {
  const clock = embedData?.game_info?.game_clock;
  const suffix = (number) => {
    if (number > 3) return "th";
    switch (number) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  if (!clock) return "00:00 0th";

  if (leagueType === "ncaa" || leagueType === "college_mens") {
    if (clock.includes("Q1 0:00")) return "Halftime";
    if (clock.includes("Q2 0:00") && embedData?.game_info?.home_score == embedData?.game_info?.away_score)
      return "Overtime";
    if (
      clock.includes("OT") &&
      clock.includes(" 0:00") &&
      embedData?.game_info?.home_score != embedData?.game_info?.away_score
    )
      return "Final";
    // if (clock.includes("OT")) return "Overtime";
    if (clock.includes("Q2 0:00")) return "Final";
  }
  if (leagueType === "nba" || leagueType === "wnba") {
    if (clock.includes("Q2 0:00")) return "Halftime";
    if (clock.includes("Q4 0:00") && embedData?.game_info?.home_score == embedData?.game_info?.away_score)
      return "Overtime";
    if (
      clock.includes("OT") &&
      clock.includes(" 0:00") &&
      embedData?.game_info?.home_score != embedData?.game_info?.away_score
    )
      return "Final";
    // if (clock.includes("OT")) return "Overtime";
    if (clock.includes("Q4 0:00")) return "Final";
  }
  const [time, period] = clock.split(" ").reverse();

  let modifiedPeriod = "--";
  if (period && period.includes("OT")) {
    modifiedPeriod = period + suffix(+period.slice(2));
  } else if (period) {
    modifiedPeriod = period.slice(1) + suffix(+period.slice(1));
  }

  return `${time} ${modifiedPeriod}`;
};
